<template>
    <div class="support">
      <b-skeleton class="image-wrapper" animation="wave"/>
      <b-skeleton class="content-wrapper" animation="wave"/>
    </div>
</template>

<script>
export default {
  name: "SupportSkeletonBox"
};
</script>
