<template>
  <div class="home-page">
    <Intro />
    <div class="background-homepage">
      <InfoCards :infoData="infoCardsContent" />
    </div>
    <Partners />
    <CooperationPartners />
    <div class="background-change">
      <Change />
    </div>
    <div class="background">
      <Support :content="supportContent" />
    </div>
    <div class="background-custom">
      <Customer />
      <CustomerLogo class="max-width" />
    </div>
    <Quote />
  </div>
</template>

<script>
const Intro = () => import("@/components/home-page/Intro");
const InfoCards = () => import("@/components/home-page/InfoCards");
const Customer = () => import("@/components/home-page/Customer");
const CustomerLogo = () => import("@/components/home-page/CustomerLogo");
const Quote = () => import("@/components/home-page/Quote.vue");
const CooperationPartners = () => import("@/components/home-page/CooperationPartners.vue");
import lazyLoadComponent from "@/helpers/lazy-load-component.js";
import ChangeSkeletonBox from "@/components/home-page-skeleton-loaders/ChangeSkeletonBox";

import SupportSkeletonBox from "@/components/home-page-skeleton-loaders/SupportSkeletonBox";
import PartnersSkeletonBox from "@/components/home-page-skeleton-loaders/PartnersSkeletonBox";

export default {
  name: "HomePage",

  metaInfo: {
    title: "Sveriges enklaste ekonomisystem",
    meta: [
      {
        name: "description",
        content:
          "Sveriges enklaste bokföringsprogram och faktureringsprogram - Allt vi gör på acconomy handlar om att göra bokföring och ekonomi så enkelt som möjligt för Sveriges alla småföretagare."
      },
      {
        name: "keywords",
        content:
          "ekonomisystem, faktureringsystem, faktureringsprogram, bokföringsystem, bokföringsprogram, automatiserad bokföring, enkelt faktureringsprogram, enkelt bokföringprogram"
      }
    ]
  },

  data() {
    return {
      infoCardsContent: [
        {
          id: 1,
          icon: "info-share-2.svg",
          header: "Bankkoppling",
          description:
            "Aktivera bankkoppling och betala dina leverantörers fakturor direkt i acconomy."
        },
        {
          id: 2,
          icon: "info-tablet.svg",
          header: "Smidig fakturering via mobilen & läsplattor",
          description:
            "Tydlig och enkel navigation – Snabb bokföring med superenkel fakturering."
        },
        {
          id: 3,
          icon: "info-map.svg",
          header: "Enkel bokföring med molntjänst",
          description:
            "Sköt din bokföring och fakturering var du än befinner dig."
        },
        {
          id: 4,
          icon: "info-headphones.svg",
          header: "Support dygnet runt",
          description:
            "Om det skulle krångla för dig? Nås dygnet runt alla dagar i veckan."
        }
      ],
      supportContent: {
        img: "help@2x.webp",
        header: "Världens mest förstående support. Dygnet runt. Året runt.",
        paragraphs: [
          "Vår support är öppen alla dagar på året, dygnet runt och helt kostnadsfritt. Ring eller ta kontakt med oss precis när som helst om något hänger sig eller om du undrar något."
        ],
        vhtml: /*html*/ `
         
        <div class="v-html-content">
           <span>Ring eller mejla oss</span>
            <div class="contact-container">
            <p tabindex="0" class="text-link text-emphasize">+46 (0)31 309 91 11</p>
            <p tabindex="0" class="text-link email text-emphasize">support@acconomy.com</p>
            </div>
        </div>
        
        `
      }
    };
  },

  components: {
    Intro,
    InfoCards,
    Customer,
    CustomerLogo,
    Quote,
    CooperationPartners,
    Change: lazyLoadComponent({
      componentFactory: () => import("@/components/home-page/Change.vue"),
      loading: ChangeSkeletonBox
    }),
    Support: lazyLoadComponent({
      componentFactory: () => import("@/components/common/Support.vue"),
      loading: SupportSkeletonBox
    }),
    Partners: lazyLoadComponent({
      componentFactory: () => import("@/components/home-page/Partners.vue"),
      loading: PartnersSkeletonBox
    })
  },

  mounted() {
    // this.$bvModal.show("video-modal");
  }
};
</script>
<style>
.background-custom {
  padding-top: 10rem;
  background-color: white;
}

.background-change {
  background-color: white;
}

.background-homepage {
  background-color: #7756fb;
  color: white;
}

.background-homepage>.info-container>div>.info-card>.info-text {
  color: white;
}

.background {
  background-color: white;
}

.background>.support>.content-wrapper {
  background: #7756fb;
}

.background>.support>.content-wrapper>.text-wrapper>.header {
  color: white;
}

.background>.support>.content-wrapper>.text-wrapper>.text {
  color: white;
}

.v-html-content {
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  height: 127px;
  background-color: white;
  border: solid white;
  border-radius: 8px;
}

.v-html-content span {
  margin: auto;
  color: black;
}

.contact-container {
  display: flex;
  width: 476px;
  justify-content: space-between;
  margin: auto;
  color: rgb(67, 67, 190);
}



@media (max-width: 992px) {
  .contact-container {
    width: 80%;
    flex-direction: column;
    gap: 10px;
    margin: auto;
  }
}

@media (max-width: 567px) {
  .contact-container {
    .text-link {
      font-size: 1.4rem;
    }
  }
}
</style>
